import React from 'react';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Container, Box, Typography, Card, CardActionArea, CardHeader, CardMedia } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ApiIcon from '@mui/icons-material/Api';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import BadgeIcon from '@mui/icons-material/Badge';
import { navigate } from 'gatsby';

const containerCSS = css`
display: flex; 
align-items: center; 
justify-content: space-evenly;
flex-wrap: wrap-reverse;
border-bottom: 1px solid;
`;
const BoxCss = css`
    max-width: 720px;
`;
const imageListCss = css`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
`;

const IndexPage = () => {
    return (
        <Container disableGutters css={css`display: grid; gap: 24px`}>
            <Container disableGutters css={containerCSS}>
                <Box css={BoxCss}>
                    <Typography gutterBottom component="h1" variant="h3" align="center">
                        Hello everyone,<br />I'm Michael Marchio!
                    </Typography>
                    <Typography paragraph component="p" variant="h6">
                        I Architect, Design, Develop & Deliver end-to-end software solutions. I'm based out of Charleston, South Carolina and currently solutioning as a UX Architect with Tyler Technologies.
                    </Typography>
                </Box>
                <StaticImage css={css`border-radius: 36%`} quality={100} src="https://www.gravatar.com/avatar/6333216c28036c5f55f7def47088708e?s=250" alt="headshot" />
            </Container>
            <Container disableGutters>
                <Typography>
                    Looking to learn more about me?
                    Feel free to explore around or reach out on LinkedIn!
                </Typography>
            </Container>
            <Container disableGutters css={imageListCss}>
                <Card>
                    <CardActionArea css={css`height: 100%`} onClick={()=>navigate('/aboutMe')}>
                        <CardHeader titleTypographyProps={{ variant: 'h5' }} avatar={<BadgeIcon fontSize='large' />} title="About Me" subheader="Odds and ends about me." />
                    </CardActionArea>
                </Card>
                <Card>
                    <CardActionArea css={css`height: 100%`} onClick={() => navigate('/design')}>
                        <CardHeader titleTypographyProps={{variant: 'h5'}} avatar={<DesignServicesIcon fontSize='large' />} title="Design" subheader="Explore design studies and other areas of my design career." />
                    </CardActionArea>
                </Card>
                <Card>
                    <CardActionArea css={css`height: 100%`} onClick={() => navigate('/development')}>
                        <CardHeader titleTypographyProps={{ variant: 'h5' }} avatar={<ApiIcon fontSize='large' />} title="Development" subheader="Playground where I test out new APIs and features." />
                    </CardActionArea>
                </Card>
                <Card>
                    <CardActionArea css={css`height: 100%`} onClick={() => navigate('/experience')}>
                        <CardHeader titleTypographyProps={{ variant: 'h5' }} avatar={<LocalLibraryIcon fontSize='large' />} title="Experience" subheader="My professional work history in design and development" />
                    </CardActionArea>
                </Card>
            </Container>
        </Container>
    )
}

export default IndexPage;